// @ts-nocheck
import { ThemeProvider } from 'styled-components';
import { StyledImage } from './components/LandPage/styles';
import { GlobalStyle } from './styles/global';
import { themes } from './theme';

export function App() {
  // 
  return (
    <ThemeProvider theme={themes['RoseTheme']}>
      <title>LC Promotora</title>
      <GlobalStyle></GlobalStyle>
      <div style={{ display: "flex", backgroundColor: "#0096E5", flex: 1, minWidth: "100vw", minHeight: "100vh", margin: 0, justifyContent: "center", alignItems: "center", flexDirection: "column" }} >

        <h1 style={{ marginBottom: "10px", }} >Estamos em Manutenção</h1>
        <h4 style={{ maxWidth: "700px", marginBottom: "30px" }} >Olá! No momento, nosso site está passando por uma manutenção programada para melhorar nossos serviços e proporcionar uma melhor experiência para você. Pedimos desculpas por qualquer inconveniente.</h4>
        <StyledImage src={require('../src/assets/LC Promotora.jpeg')}
        ></StyledImage>
      </div>
    </ThemeProvider>
  )
}

export default App
