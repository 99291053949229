import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  button {
    cursor: pointer;
  }
  html{
    background-color: #6B1742;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Manrope', sans-serif;

    
    
    
  }
  a, span, h1,h2,h3,h4,li{
    color:#fafafa
    
  }
  li{
     list-style-type: none ;
     font-size: 20px;
  }
  `;